
import { Vue, Component, Prop } from "vue-property-decorator";

export interface DstvChannelItem {
    name: string;
    category: string;
    channel: number;
    logo: string | null;
}

@Component
export default class DstvChannel extends Vue {
    @Prop({ required: true, type: Object }) item!: DstvChannelItem;
}
